import React, { useState, DragEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, IconButton, LinearProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { apiService } from './../services/api/ApiService';
import SimpleNotification from './SimpleNotification';

interface NewAnalysisModalProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

const NewAnalysisModal: React.FC<NewAnalysisModalProps> = ({ open, onClose, onUploadSuccess }) => {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>('');
  const [numberCase, setNumberCase] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<'success' | 'error' | 'warning'>('success');
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === 'application/pdf') {
        setFile(selectedFile);
      } else {
        setNotificationType('error');
        setNotificationMessage('Por favor, selecciona un archivo PDF.');
        setNotificationOpen(true);
      }
    }
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.type === 'application/pdf') {
        setFile(droppedFile);
      } else {
        setNotificationType('error');
        setNotificationMessage('Por favor, selecciona un archivo PDF.');
        setNotificationOpen(true);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!file || !title || !numberCase) {
      setNotificationType('error');
      setNotificationMessage('Por favor completa todos los campos y selecciona un archivo.');
      setNotificationOpen(true);
      return;
    }
  
    setLoading(true);
  
    try {
      const userProfile = localStorage.getItem('userProfile');
      const userData = userProfile ? JSON.parse(userProfile) : null;
  
      if (!userData || !userData.id) {
        setNotificationType('error');
        setNotificationMessage('No se encontró el perfil del usuario.');
        setNotificationOpen(true);
        return;
      }
  
      const userId = userData.id;
      const companyId = 1;
  
      const response = await apiService.uploadData(file, title, numberCase, userId, companyId);
      console.log('Respuesta de la API:', response);
  
      setNotificationType('success');
      setNotificationMessage('Archivo subido y análisis realizado correctamente.');
      setNotificationOpen(true);
  
      onUploadSuccess();
      onClose();

      setFile(null);
      setTitle('');
      setNumberCase('');

    } catch (error) {
      console.error('Error al subir el archivo:', error);
      setNotificationType('error');
      setNotificationMessage('Error al subir el archivo. Inténtalo de nuevo.');
      setNotificationOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Nuevo Análisis de Precedentes</Typography>
            <IconButton onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              id="titulo"
              name="titulo"
              label="Título del Análisis"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <TextField
              fullWidth
              margin="normal"
              id="numberCase"
              name="numberCase"
              label="Número de Expediente"
              variant="outlined"
              value={numberCase}
              onChange={(e) => setNumberCase(e.target.value)}
              required
            />

            <Box
              mt={2}
              mb={2}
              p={2}
              border={`2px dashed ${isDragging ? '#002E74' : '#ccc'}`}
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              sx={{
                backgroundColor: isDragging ? 'rgba(0, 46, 116, 0.05)' : 'transparent',
                transition: 'all 0.2s ease-in-out'
              }}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <FontAwesomeIcon 
                icon={faUpload} 
                style={{ 
                  fontSize: '3rem', 
                  color: isDragging ? '#002E74' : '#A0AEC0',
                  transition: 'color 0.2s ease-in-out'
                }} 
              />
              <Typography variant="body2" color="#002E74">
                <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#3182CE' }}>
                  Haz clic
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </label>
                {' o arrastra un archivo PDF aquí para subirlo'}
              </Typography>
              {file && (
                <Typography variant="body2" mt={1}>
                  Archivo seleccionado: {file.name}
                </Typography>
              )}
            </Box>
          </form>
        </DialogContent>

        <DialogActions>
          <Box position="relative" width="100%">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              sx={{ backgroundColor: '#002E74', color: '#fff' }}
            >
              {loading ? 'Subiendo...' : 'Subir Archivo y Analizar'}
            </Button>

            {loading && (
              <LinearProgress
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '4px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <SimpleNotification
        open={notificationOpen}
        onClose={() => setNotificationOpen(false)}
        type={notificationType}
        message={notificationMessage}
      />
    </>
  );
};

export default NewAnalysisModal;