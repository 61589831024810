import React, { useState, DragEvent } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiService } from './../services/api/ApiService';
import NotificationBanner from './common/NotificationBanner';
import { useNavigate } from 'react-router-dom';

const config = {
  chunkSize: 5 * 1024 * 1024,
};

const validFormats = ['mp4', 'mp3', 'mkv', 'wav', 'asf'];
const bucketName = process.env.REACT_APP_BUCKET_NAME || "default";

const NewTranscription: React.FC<{ setActiveItem: (item: string) => void }> = ({ setActiveItem }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [transcriptionName, setTranscriptionName] = useState<string>('');
  const [caseNumber, setCaseNumber] = useState<string>('');
  const [category, setCategory] = useState<string>('Derecho Administrativo');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    type: 'error' as 'success' | 'error',
    title: '',
    message: '',
    open: false,
  });

  const navigate = useNavigate();

  const isFormComplete = () => {
    return transcriptionName && caseNumber && category && files.length > 0;
  };

  const validateAndAddFiles = (newFiles: File[]) => {
    if (files.length + newFiles.length > 1) {
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Puedes subir un máximo de 1 archivo.',
        open: true,
      });
      return;
    }

    const validFiles = newFiles.filter(file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return validFormats.includes(fileExtension || '');
    });

    if (validFiles.length !== newFiles.length) {
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Algunos archivos tienen un formato no soportado y no fueron agregados.',
        open: true,
      });
    }

    setFiles(validFiles.length > 0 ? [...files, ...validFiles] : files);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      validateAndAddFiles(Array.from(event.target.files));
    }
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const droppedFiles = Array.from(e.dataTransfer.files);
    validateAndAddFiles(droppedFiles);
  };

  const handleFileRemove = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    // ... resto del código handleSubmit sin cambios ...
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
      <Container maxWidth="lg">
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2 }}>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Transcriptor/Nueva transcripción
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '40px',
              fontWeight: 600,
              lineHeight: '48.76px',
              textAlign: 'left'
            }}
            gutterBottom
          >
            Nueva transcripción
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph sx={{ mb: 3 }}>
            Esta herramienta está específicamente diseñada para transcribir sesiones judiciales. Para utilizarla correctamente, ingresa el nombre de la sesión, el número de expediente y selecciona la categoría correspondiente. Luego, sube el archivo de audio o video relacionado de la sesión judicial, una vez procesado, el resultado estará disponible en la pestaña "Mis transcripciones".
          </Typography>

          <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Nombre de la transcripción"
                  variant="outlined"
                  margin="normal"
                  value={transcriptionName}
                  onChange={(e) => setTranscriptionName(e.target.value)}
                  sx={{ height: '56px' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Número de expediente"
                  variant="outlined"
                  margin="normal"
                  value={caseNumber}
                  onChange={(e) => setCaseNumber(e.target.value)}
                  sx={{ height: '56px' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Select
                  fullWidth
                  displayEmpty
                  variant="outlined"
                  margin="dense"
                  sx={{ height: '56px', mt: 2, mb: 1, fontStyle: 'normal', fontWeight: 'normal' }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value as string)}
                >
                  <MenuItem disabled value="" sx={{ fontStyle: 'normal' }}>
                    <em>Categoría</em>
                  </MenuItem>
                  <MenuItem value="Derecho Penal">Derecho Penal</MenuItem>
                  <MenuItem value="Derecho Civil">Derecho Civil</MenuItem>
                  <MenuItem value="Derecho Mercantil">Derecho Mercantil</MenuItem>
                  <MenuItem value="Derecho Administrativo">Derecho Administrativo</MenuItem>
                  <MenuItem value="Derecho Laboral">Derecho Laboral</MenuItem>
                  <MenuItem value="Derecho Constitucional">Derecho Constitucional</MenuItem>
                  <MenuItem value="Derecho Internacional">Derecho Internacional</MenuItem>
                  <MenuItem value="Derecho Ambiental">Derecho Ambiental</MenuItem>
                  <MenuItem value="Derecho Agrario">Derecho Agrario</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Box
              sx={{
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                cursor: 'pointer',
                mb: 3,
                backgroundColor: isDragging ? '#e3f2fd' : '#f0f0f0',
                border: isDragging ? '2px dashed #1976d2' : '2px dashed #9e9e9e',
                transition: 'all 0.3s ease'
              }}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                accept="audio/*,video/*"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <CloudUploadIcon sx={{ 
                  fontSize: 50, 
                  color: isDragging ? '#1976d2' : '#9e9e9e',
                  transition: 'color 0.3s ease'
                }} />
                <Typography variant="h6" color="textSecondary">
                  {isDragging 
                    ? 'Suelta el archivo aquí' 
                    : 'Arrastra y suelta o haz clic para subir audio o video'
                  }
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Formatos soportados: MP4, MP3, MKV, WAV, ASF
                </Typography>
              </label>
              <List sx={{ mt: 2 }}>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={file.name}
                      secondary={`${(file.size / (1024 * 1024)).toFixed(2)} MB`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>

            {isUploading && <LinearProgress sx={{ mb: 2 }} variant="determinate" value={uploadProgress} />}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isUploading || !isFormComplete()}
              fullWidth
              sx={{
                height: '56px',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fontSize: '20px',
                mb: 3,
              }}
            >
              {isUploading ? 'Subiendo...' : 'Subir Archivo y Generar Transcripción'}
            </Button>
          </Box>

          <NotificationBanner
            type={notification.type}
            title={notification.title}
            message={notification.message}
            open={notification.open}
            onClose={handleCloseNotification}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default NewTranscription;